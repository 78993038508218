import React from "react";
import { closePopup } from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "../Form/Form";
import "./Popup.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faClose } from "@fortawesome/free-solid-svg-icons";

export default function Popup() {
  const popUp = useSelector((state) => state.changeState);
  const dispatch = useDispatch();

  const disableScroll = () => {
    document.getElementsByTagName("html")[0].style.overflow = "hidden";
  };
  const enableScroll = () => {
    document.getElementsByTagName("html")[0].style.overflow = "";
    dispatch(closePopup());
  };
  // if (popUp) {
  return (
    <Offcanvas
      show={popUp}
      onEntered={disableScroll}
      onHide={enableScroll}
      onExit={enableScroll}
      autoFocus={true}
      placement="end"
      scroll={false}
      className="dark"
    >
      <Offcanvas.Header>
        {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
        <div className="form-heading-div">
          <FontAwesomeIcon
            className="fa-2x"
            icon={faPaperPlane}
          ></FontAwesomeIcon>
          <h1 className="form-main-heading">Get Quote</h1>
        </div>
        <div className="p-2" onClick={() => enableScroll()}>
          <FontAwesomeIcon className="navbar-close-icon" icon={faClose} />
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form />
      </Offcanvas.Body>
    </Offcanvas>
    // <>
    //   <div
    //     onClick={() => dispatch(closePopup())}
    //     className={"popupUnuseBG " + (popUp ? "showPopUp" : "")}
    //   ></div>
    //   <div className={"popUpContent " + (popUp ? "showPopUp" : "")}>
    //
    //   </div>
    // </>
  );
  // }
}
