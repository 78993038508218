import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { openPopup } from "../../redux/actions";
import { faUsers, faEye, faHandshake } from "@fortawesome/free-solid-svg-icons";

import "./WelcomeScreen.css";

export default function WelcomeScreen() {
  const dispatch = useDispatch();

  return (
    <Container fluid="true" className="welcomScreenMainContainer">
      <Container className="welcome-heading-container">
        <Container fluid="true">
          <Row>
            <Col xs={6} md={6}>
              <div className="img-banner-container">
                <div className="bg-patch hidden2 left tran2s"></div>
                {/* <img alt="bg" src="/graph.png" className="bg-patch" /> */}
                <img
                  alt="banner"
                  className="img-banner  hidden2 left tran2s"
                  src="/banner_image.png"
                />
              </div>
            </Col>
            <Col xs={6} md={6}>
              <div className="welcomeScreenDiv">
                <p className="welcomeScreenTagLine hidden2 left tran2s delay05s">
                  {"Leave no stone unturned".toUpperCase()}
                </p>
                <p className="welcomeScreenHeading hidden2 right tran2s ">
                  We Bring Results
                </p>
                <button
                  onClick={() => dispatch(openPopup())}
                  className="welcomeScreen_Buttons hidden2 top tran5s "
                >
                  GET QUOTE
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="welcomeCardConainer">
        <div className="welcomeTable">
          {/* <div className="welcomeCardRow"> */}
          <div className="welcomeCell hidden tran2s">
            <div className="welcomeCellBody">
              <FontAwesomeIcon
                icon={faUsers}
                className="welcomeCardIcon fa-3x"
              ></FontAwesomeIcon>
              <div className="welcomeCardHeading">Who We Are.</div>
              <div className="welcomeCardHR"></div>
              <div className="welcomeCardDiscription">
                We are a top-performing BPO services company, offering
                cutting-edge solutions to businesses of all sizes. Our expert
                team is dedicated to handling back-end operations, freeing up
                our partners to focus on growth and success.
              </div>
            </div>
          </div>
          <div className="welcomeCell hidden tran2s delay05s">
            <div className="welcomeCellBody">
              <FontAwesomeIcon
                icon={faEye}
                className="welcomeCardIcon fa-3x"
              ></FontAwesomeIcon>
              <div className="welcomeCardHeading">Our Vision.</div>
              <div className="welcomeCardHR"></div>
              <div className="welcomeCardDiscription">
                Our aim is to become the leading BPO services provider,
                delivering exceptional quality and efficiency to our valued
                partners. We aspire to be the preferred choice for businesses
                seeking reliable and effective BPO services.
              </div>
            </div>
          </div>
          <div className="welcomeCell hidden tran2s delay1s">
            <div className="welcomeCellBody">
              <FontAwesomeIcon
                icon={faHandshake}
                className="welcomeCardIcon fa-3x"
              ></FontAwesomeIcon>
              <div className="welcomeCardHeading">Our Mission</div>
              <div className="welcomeCardHR"></div>
              <div className="welcomeCardDiscription">
                Our mission is to support the growth and success of our partners
                through unparalleled BPO services. We remain dedicated to
                continuously improving and modernizing our offerings to meet the
                ever-changing needs of our partners. Our core philosophy of
                taking care of back-end operations to fuel growth is at the
                heart of all we do.
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </Container>

      <div className="hr-div--allAlone hidden top tran1s delay1s">
        <div className="hr--allAlone"></div>
      </div>
    </Container>
  );
}
