import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faChevronRight,
  faLocationDot,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { openPopup } from "../../redux/actions";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { socialMedia } from "../../Details/SocialMedia";
import "./Footer.css";

export default function Footer() {
  const dispatch = useDispatch();
  return (
    <Container className="maxWidth">
      <Row>
        <Col md={6} xl={3}>
          <h1 className="footerBrand">Qwik Solvers</h1>
          <p className="footerBrandDesc">
            We utilize technology to provide top-notch services aimed at
            enhancing customer engagement and optimizing business performance.
          </p>
        </Col>

        <Col md={6} xl={3}>
          <h1 className="listStyle">Enquire Now </h1>
          <div onClick={() => dispatch(openPopup())} className="listDiv">
            <FontAwesomeIcon className="chevronRight" icon={faChevronRight} />
            <p className="listName">Buisness Enquiry</p>
          </div>

          {/* <Link to={"/career"} className="null-atag"> */}
          <a href={`/career`}>
            <div className="listDiv">
              <FontAwesomeIcon className="chevronRight" icon={faChevronRight} />
              <p className="listName">Career</p>
            </div>
          </a>
          {/* </Link> */}
        </Col>

        <Col md={6} xl={3}>
          <h1 className="listStyle">Quick Links</h1>

          <a href="/#about" className="null-atag">
            <div className="listDiv">
              <FontAwesomeIcon className="chevronRight" icon={faChevronRight} />
              <p className="listName">About Us</p>
            </div>
          </a>

          <a href="/#services" className="null-atag">
            <div className="listDiv">
              <FontAwesomeIcon className="chevronRight" icon={faChevronRight} />
              <p className="listName">Services</p>
            </div>
          </a>

          <a href="/#work" className="null-atag">
            <div className="listDiv">
              <FontAwesomeIcon className="chevronRight" icon={faChevronRight} />
              <p className="listName">Our Experties</p>
            </div>
          </a>

          <a href="/#blog" className="null-atag">
            <div className="listDiv">
              <FontAwesomeIcon className="chevronRight" icon={faChevronRight} />
              <p className="listName">Blog</p>
            </div>
          </a>
        </Col>

        <Col md={6} xl={3}>
          <h1 className="listStyle">Contact</h1>

          <div className="listDiv">
            <FontAwesomeIcon className="chevronRight" icon={faLocationDot} />
            <p className="listName">UGF 1A, Ravindrapali, Lucknow - 226016</p>
          </div>
          <div className="listDiv">
            <FontAwesomeIcon className="chevronRight" icon={faPhone} />
            <p className="listName">(+91) 9450948801</p>
          </div>
          <div className="listDiv">
            <FontAwesomeIcon className="chevronRight" icon={faEnvelope} />
            <p className="listName">contact@qwiksolvers.com</p>
          </div>
        </Col>
      </Row>

      <hr className="hr-end" />

      <Row>
        <Col sm={6}>
          <p className="footerCopyRight">
            Copyright © 2023. All rights reserved.
          </p>
        </Col>
        <Col sm={6}>
          <div className="footerSocialMediaIcon">
            <div className="footerIconsDivMain">
              <a href={socialMedia.twitter} target="true" className="null-atag">
                <div className="footerIconDiv">
                  <FontAwesomeIcon className="icons" icon={faTwitter} />
                </div>
              </a>

              <a
                href={socialMedia.facebook}
                target="true"
                className="null-atag"
              >
                <div className="footerIconDiv">
                  <FontAwesomeIcon className="icons" icon={faFacebookF} />
                </div>
              </a>

              <a
                href={socialMedia.instagram}
                target="true"
                className="null-atag"
              >
                <div className="footerIconDiv">
                  <FontAwesomeIcon className="icons" icon={faInstagram} />
                </div>
              </a>

              <a href={socialMedia.youtube} target="true" className="null-atag">
                <div className="footerIconDiv">
                  <FontAwesomeIcon className="icons" icon={faYoutube} />
                </div>
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
