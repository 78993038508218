let position = 0;
let mouse = null;
let blob;
(function () {
  window.addEventListener(
    "scroll",
    () => {
      position = window.pageYOffset;

      if (mouse && blob) {
        blob.animate(
          {
            left: `${mouse.clientX}px`,
            top: `${mouse.clientY + position}px`,
          },
          { duration: 3000, fill: "forwards" }
        );
      }
    },
    {
      passive: true,
    }
  );

  function getWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }

  // function getHeight() {
  //   return Math.max(
  //     document.body.scrollHeight,
  //     document.documentElement.scrollHeight,
  //     document.body.offsetHeight,
  //     document.documentElement.offsetHeight,
  //     document.documentElement.clientHeight
  //   );
  // }

  window.addEventListener(
    "load",
    function () {
      let width = getWidth();
      if (width < 500) {
        return;
      }

      this.setTimeout(() => {
        // blob = document.querySelector("#blob");
        // console.log(blob);
        const homeDiv = document.querySelector(".HomePageDiv");
        
        if(!homeDiv){
          return
        }

        blob = document.createElement("div");
        blob.id = "blob";
        homeDiv.appendChild(blob);
        blob.animate(
          {
            opacity: 0.5,
          },
          { duration: 500, fill: "forwards" }
        );

        document.body.onpointermove = (event) => {
          const { clientX, clientY } = event;
          if (mouse) {
            blob.style.left = `0px`;
            blob.style.top = `0px`;
          }
          mouse = event;
          blob.animate(
            {
              left: `${clientX}px`,
              top: `${clientY + position}px`,
            },
            { duration: 3000, fill: "forwards" }
          );
        };
      }, 1000);
    },
    false
  );
})();
