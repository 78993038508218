/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";
import { tagLineTwo, aboutDesc } from "./AboutData";
// import SectionHeader from "../common/SectionHeader";

import React from "react";
import "./About.css";

import { Container, Row, Col } from "react-bootstrap";

export default function About() {
  return (
    <Container fluid="true" className="aboutMaxWidth ">
      <a id="about"></a>
      <Container className="aboutMainDiv ">
        {/* <SectionHeader heading={Aboutheading} tagLine={""} /> */}
        <Container fluid="true" className="service-containerMain about-block">
          <Row>
            <Col md={6} xl={6} sm={12}>
              <div className="img-block  hidden left tran2s"></div>
            </Col>
            <Col md={6} xl={6} sm={12}>
              <div className="content-block">
                <div className="content-center">
                  {/* <h1 className="about-subHeading"> */}
                  <div className="inner-header">
                    <h3 className="section-heading hidden right tran2s">
                      {tagLineTwo}
                    </h3>
                    <div className="section-heading-line hidden right tran2s"></div>
                  </div>
                  {/* </h1> */}
                  <p className=" hidden right tran2s">{aboutDesc}</p>
                  <div className="hidden right tran1s delay1s">
                    <button
                      // onClick={() => dispatch(openPopup())}
                      className="mid-button "
                    >
                      <FontAwesomeIcon icon={faBookOpen} className="mr-10" />
                      READ MORE
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        {/* <div className="about-block"></div> */}
        {/* <h1 className="about-subHeading hidden left tran2s delay1s ">
          {tagLineTwo}
        </h1>
        <p className="about-desc hidden left tran2s delay15s ">{secondDesc}</p>
        <h1 className="about-subHeading hidden right tran2s delay2s">
          {tagLine}
        </h1>
        <p className="about-desc  hidden right tran2s delay25s ">{firstDesc}</p> */}
      </Container>
    </Container>
  );
}
