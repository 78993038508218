/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import "./Works.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Card } from "react-bootstrap";
import { heading, cards, tagLine } from "./WorksData";
import SectionHeader from "../common/SectionHeader";
import { splitArray } from "../../utlts/common-utils";

export default function Works() {
  return (
    <Container fluid="true" className="worksMainDiv">
      <a id="work"></a>
      <Container className="workMaxWidth">
        <SectionHeader heading={heading} tagLine={tagLine} />
        <Container fluid="true" className="service-containerMain ">
          {splitArray(cards, 3).map((chunk, idx) => {
            return (
              <Row key={idx}>
                {chunk.map((newItem, newIndex) => {
                  return (
                    <Col
                      key={`${idx}_${newIndex}`}
                      sm={4}
                      className={
                        " hidden tran1s mb-20 " + newItem["delayClass"]
                      }
                    >
                      <Card key={newIndex} className="services-cards">
                        <Card.Body key={newIndex}>
                          <FontAwesomeIcon
                            className="fa-2x serviceIcons"
                            icon={newItem["img"]}
                            alt="paper"
                          />
                          <Card.Title className="serviceCardHeading">
                            {newItem["heading"]}
                          </Card.Title>
                          <Card.Text className="serviceCardDiscription mb-10">
                            {newItem.desc}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            );
          })}
        </Container>
      </Container>
    </Container>
  );
}
