import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import NavBar from "../../Components/NavBar/NavBar";
import "./BlogArticle.css";
import { useParams } from "react-router-dom";
// import PageEnd from "../../Components/PageEnd/PageEnd";
import Footer from "../../Components/Footer/Footer";
import Popup from "../../Components/common/Popup";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import data from "./../../data/articles.json";
import { stringToSlug } from "../../utlts/common-utils";

export default function BlogArticle() {
  const param = useParams();
  // console.log(param);

  const [article, setArticle] = useState(null);

  useEffect(() => {
    const index = data.findIndex(
      (item) => stringToSlug(item.title) === param.id
    );
    setArticle(data[index]);
  }, [param.id]);

  return article != null ? (
    <div className="full-width">
      <Container fluid={true} className="posAbs">
        <NavBar />
      </Container>

      <div className="blogArticle-image-div">
        <img
          alt="blog_image"
          className="blogArticle-image"
          src={article["image"]}
        ></img>
        <div className="img-cover"></div>

        <div className="blog-title">
          <Container className="blogArticle-Container ">
            <Row>
              <Col md={2}></Col>
              <Col md={7}>
                <h1 className="blogArticle-heading">{article["title"]}</h1>
                <div className="blog-info">
                  <span className="author">{article.author}</span>
                  <span className="blog-date">{article.date}</span>
                  <ul class="tags">
                    {article.tags.map((e) => (
                      <li>{e}</li>
                    ))}
                  </ul>
                </div>
              </Col>
              <Col md={3}></Col>
            </Row>
          </Container>
        </div>
      </div>

      <Container className="blogArticle-Container mt-20" fluid={true}>
        <Row>
          <Col md={2}>
            <div className="share-blog-container">
              <FacebookShareButton url={window.location.href}>
                <FacebookIcon size={50} round />
              </FacebookShareButton>
              <TwitterShareButton url={window.location.href}>
                <TwitterIcon size={50} round />
              </TwitterShareButton>
              <LinkedinShareButton url={window.location.href}>
                <LinkedinIcon size={50} round />
              </LinkedinShareButton>
            </div>
          </Col>
          <Col md={7}>
            <div
              className="blogArticle-paragraphs"
              dangerouslySetInnerHTML={{ __html: article["body"] }}
            ></div>
            <hr className="blogArticle-hr" />
          </Col>
          <Col md={3}>
            <div className="more-articles">
              <h4>More Articles</h4>
              <ul>
                <li>
                  <a href="/">
                    Importance of BPO(Business Process Outsourcing) in India.
                  </a>
                </li>
                <li>
                  <a href="/">
                    What Is Business Process Outsourcing (BPO), and How Does It
                    Work?
                  </a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        {/* <h1 className="blogArticle-heading">
            {blogData[param.id]["heading"]}
          </h1> */}
        {/* <hr className="blogArticle-hr-heading" /> */}
      </Container>

      {/* <PageEnd /> */}
      <Footer />
      <Popup />
      {/* </Container> */}
    </div>
  ) : (
    <div></div>
  );
}
