export const Aboutheading = "About";
export const tagLine = "OUR PHILOSPHY";
export const firstDesc = ` "Who We Are - BPO Services Provider"
                            We are a top-performing BPO services 
                            company, offering cutting-edge solutions 
                            to businesses of all sizes. Our expert 
                            team is dedicated to handling back-end
                             operations, freeing up our partners 
                             to focus on growth and success. 
                             "Our Vision - Premier BPO Services Provider" 
                             Our aim is to become the leading BPO services 
                             provider, delivering exceptional quality and efficiency 
                             to our valued partners. We aspire to be the preferred choice 
                             for businesses seeking reliable and effective BPO services. 
                             "Our Mission - Empowering Business Growth with BPO Services" 
                             Our mission is to support the growth and success of our partners 
                             through unparalleled BPO services. We remain dedicated to continuously 
                             improving and modernizing our offerings to meet the ever-changing 
                             needs of our partners. Our core philosophy of taking care of 
                             back-end operations to fuel growth is at the heart of all we do.`;

export const tagLineTwo = "ABOUT US";

export const aboutDesc = `We are leading provider of support and tech support 
services, specializing in delivering high-quality, efficient solutions 
to businesses of all sizes. With a talented team of experts and a 
commitment to customer satisfaction, we help companies streamline 
their operations, improve their bottom line, and achieve their goals.`;

export const secondDesc = `Qwicksolvers BPO is a leading provider of support and tech support 
                            services, specializing in delivering high-quality, efficient solutions 
                            to businesses of all sizes. With a talented team of experts and a 
                            commitment to customer satisfaction, we help companies streamline 
                            their operations, improve their bottom line, and achieve their goals. 
                            Our services are designed to meet the unique needs of each client, 
                            and we work closely with our clients to understand their business 
                            processes, identify their pain points, and develop customized solutions 
                            that help them achieve their objectives. Our services include technical 
                            support, customer service, and help desk support, among others. At Qwicksolvers 
                            BPO, we believe in the power of technology to transform business, and we use the 
                            latest tools and techniques to help our clients stay ahead of the curve. Our experts 
                            are knowledgeable in a wide range of technologies, including cloud computing, data 
                            analytics, and artificial intelligence, and we are constantly exploring 
                            new innovations to bring to our clients. We are proud of our track 
                            record of success and our commitment to excellence. Our clients trust us to 
                            provide them with the best support and tech support services available, 
                            and we work hard to earn and maintain that trust every day. If you're looking 
                            for a partner who can help you improve your operations, drive growth, and 
                            achieve your business goals, look no further than Qwicksolvers BPO. Contact us 
                            today to learn more about our services and how we can help you succeed.`;
