import {
  faPeopleArrows,
  faChartLine,
  faHeadset,
  faChartPie,
  faClipboardCheck,
  faUsersRays,
  faMicrochip,
  faIndustry,
  faPhotoFilm,
} from "@fortawesome/free-solid-svg-icons";

export const heading = "Works";
export const tagLine = "OUR EXPERTIES";

export const cards = [
  {
    img: faPeopleArrows,
    heading: "Outsourcing Solutions",
    desc: "We offer outsourcing solutions to help businesses reduce costs, increase efficiency, and focus on core competencies, with a range of services including BPO and IT outsourcing.",
    delayClass: "delay05s",
  },
  {
    img: faChartLine,
    heading: "Process Improvement",
    desc: " We offer process improvement services to help businesses optimize their operations, reduce costs, and improve efficiency through process analysis, redesign, and implementation.",
    url: "",
    delayClass: "delay1s",
  },
  {
    img: faHeadset,
    heading: "Customer Service",
    desc: "We offer customer service solutions to help businesses provide exceptional customer experiences through phone, email, chat, and social media support, tailored to specific needs.",
    url: "",
    delayClass: "delay15s",
  },

  {
    img: faChartPie,
    heading: "Data Analytics",
    desc: "We offer data analytics services to help businesses make informed decisions by analyzing large amounts of data and providing insights to drive growth and efficiency.",
    url: "",
    delayClass: "delay1s",
  },
  {
    img: faClipboardCheck,
    heading: "Quality Assurance",
    desc: "We offer quality assurance services to help businesses ensure the highest levels of quality in their products or services through rigorous testing, analysis, and reporting.",
    url: "",
    delayClass: "delay15s",
  },
  {
    img: faUsersRays,
    heading: "Training and Development",
    desc: "We offer training and development solutions to help businesses enhance employee skills, increase productivity, and achieve business goals through customized training programs and workshops.",
    url: "",
    delayClass: "delay2s",
  },
  {
    img: faMicrochip,
    heading: "Technology",
    desc: "We offer technology solutions to help businesses stay competitive and meet evolving customer needs, with services including software development, IT consulting, and infrastructure management.",
    url: "",
    delayClass: "delay15s",
  },
  {
    img: faIndustry,
    heading: "Field Surveys & Program Implementation",
    desc: "We offer field survey and program implementation services to help businesses collect data and implement programs in the field, including site visits, data collection, and program",
    url: "",
    delayClass: "delay2s",
  },
  {
    img: faPhotoFilm,
    heading: "Animated Video Creation",
    desc: "We offer animated video creation services to help businesses create engaging, high-quality animated videos for marketing, training, and other purposes.",
    url: "",
    delayClass: "delay25s",
  },
];
