import React from "react";
import NavBar from "../../Components/NavBar/NavBar";
import Services from "../../Components/Services/Services";
import WelcomeScreen from "../../Components/WelcomeScreen/WelcomeScreen";
import Works from "../../Components/Works/Works";
import About from "../../Components/About/About";
// import Testimonials from "../../Components/Testimonials/Testimonials";
import PageEnd from "../../Components/PageEnd/PageEnd";
import Footer from "../../Components/Footer/Footer";
import Popup from "../../Components/common/Popup";
import Blog from "../../Components/Blog/Blog";
import { Container } from "react-bootstrap";
import "./HomePage.css";
import "./blob";

export default function HomePage() {
  return (
    <div className="HomePageDiv">
      {/* <div className="bg-pattern">
        <div className="top-bg-pattern"></div>
      </div> */}
      {/* <div id="blob"></div> */}
      <div>
        <Container fluid={true}>
          <NavBar />
        </Container>
        <WelcomeScreen />
        <Services />
        <Works />
        <About />
        {/* <Testimonials /> */}
        <Blog />
        <PageEnd />
        <Footer />
      </div>
      <Popup />
      <input type="hidden" id="fromHistory" value="" />
    </div>
  );
}
