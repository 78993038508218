import React from "react";
import { socialMedia } from "../../Details/SocialMedia";
import "./PageEnd.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

export default function PageEnd() {
  return (
    <div className="pageEndMainDiv">
      <div>
        <div className="pageEndHR hidden top tran1s"></div>
        <div className="pageEndIcons">

          <a href={socialMedia.twitter} target="true" className="null-atag hidden left tran1s delay1s" >
            <div className="iconDiv ">
              <FontAwesomeIcon className="icons" icon={faTwitter} />
            </div>
          </a>

          <a href={socialMedia.facebook} target="true"  className="null-atag hidden left tran1s delay1s" >
            <div className="iconDiv ">
              <FontAwesomeIcon className="icons" icon={faFacebookF} />
            </div>
          </a>

          <a href={socialMedia.instagram} target="true"  className="null-atag hidden right tran1s delay1s" >
            <div className="iconDiv">
              <FontAwesomeIcon className="icons" icon={faInstagram} />
            </div>
          </a>

          <a href={socialMedia.youtube} target="true"  className="null-atag hidden right tran1s delay1s" >
            <div className="iconDiv">
              <FontAwesomeIcon className="icons" icon={faYoutube} />
            </div>
          </a>
        </div>
        <p className="pageEndQuestion hidden  tran2s delay12s">Have any projects in mind?</p>

        <div className="buttonCenter">
          <button onClick={()=>{window.location.href = '/'}} className="pageEndButton hidden tran2s bottom delay12s">GET STARTED</button>
        </div>
      </div>
    </div>
  );
}
