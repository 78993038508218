/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import "./Blog.css";
import SectionHeader from "../common/SectionHeader";
import { Link } from "react-router-dom";
import { splitArray, stringToSlug } from "../../utlts/common-utils";
import data from "./../../data/articles.json";

export default function Blog() {
  return (
    <Container className="blog-main-container">
      <a href="#blog" id="blog"></a>
      <SectionHeader heading={"Blog"} tagLine={"CHECK OUT SOME OF OUR NEWS"} />
      <div className="blog-div">
        {splitArray(data, 3).map((chunk, idx) => {
          return (
            <Row key={idx}>
              {chunk.map((item, index) => {
                return (
                  <Col
                    sm={4}
                    key={index}
                    className="blog-col hidden tran1s delay15s"
                  >
                    <Card className="blog-card">
                      <Card.Img
                        className="blog-image"
                        variant="top"
                        src={item.image}
                      />
                      <Card.Body>
                        <Card.Title className="blog-title">
                          {item.heading}
                        </Card.Title>
                        <Card.Text className="blog-text">
                          {item.readMore}
                        </Card.Text>
                        <Link
                          className="null-atag"
                          to={"/blog/" + stringToSlug(item["title"])}
                          target="_blank"
                        >
                          <div className="blog-button-div">Read More</div>
                        </Link>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </div>
    </Container>
  );
}
