import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./anim";
import store from "./redux/store";
import { Provider } from "react-redux";
import {
  BrowserRouter,
  // createBrowserRouter,
  // RouterProvider,
} from "react-router-dom";
// import HomePage from "./Pages/HomePage/HomePage";
// import BlogArticle from "./Pages/blogArticle/BlogArticle";
// import CareerPage from "./Pages/careerPage/CareerPage";

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <HomePage />,
//   },
//   {
//     path: "/blog/:id",
//     element: <BlogArticle />,
//   },
//   {
//     path: "/career",
//     element: <CareerPage />,
//   },
// ]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>

    {/* <Provider store={store}>
      <RouterProvider router={router}></RouterProvider>
    </Provider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
