export const splitArray = (arr, count) => {
  const result = arr.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / count);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
  return result;
};

export const stringToSlug = (str) => {
  return str
    .trim()
    .toLowerCase()
    .replace(/[\W_]+/g, "-")
    .replace(/^-+|-+$/g, "");
};
