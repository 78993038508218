const initialState = false;
const changeState = (state = initialState,action)=>{
    switch(action.type){
        case "OPEN" : return true;
        case "CLOSE" : return false;
        default: return state;
    }

}

export default changeState;