import React from "react";
import { Container } from "react-bootstrap";

export default function SectionHeader({ heading, tagLine }) {
  return (
    <Container fluid="true">
      <div className="section-header">
        <div className="section-heading-line hidden left tran1s"></div>
        <h3 className="section-heading hidden left tran2s">{heading}</h3>
      </div>
      <p className="section-subheading hidden left tran2s">{tagLine}</p>
    </Container>
  );
}
