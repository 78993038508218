(function () {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      // console.log("1", entry.target);
      if (entry.isIntersecting) {
        // console.log("intersecting ", entry.target);
        entry.target.classList.add("show");
        //   } else {
        //     entry.target.classList.remove("show");
      }
    });
  });

  setTimeout(() => {
    const hiddenElements = document.querySelectorAll(".hidden");
    // console.log(hiddenElements);
    hiddenElements.forEach((e) => observer.observe(e));

    const topHiddenElements = document.querySelectorAll(".hidden2");
    // console.log(topHiddenElements);
    topHiddenElements.forEach((e) => e.classList.add("show"));

    if (
      window.performance &&
      window.performance.navigation.type ===
        window.performance.navigation.TYPE_BACK_FORWARD
    ) {
      // alert('Got here using the browser "Back" or "Forward" button.');
      hiddenElements.forEach((ele) => ele.classList.add("show"));
      topHiddenElements.forEach((ele) => ele.classList.add("show"));
    }
  }, 1000);
})();
