import {
  faLaptopCode,
  faPenToSquare,
  faUserShield,
  faHeadset,
  faFileShield,
  faClapperboard,
} from "@fortawesome/free-solid-svg-icons";

export const heading = "Services";
export const tagLine = "OUR SERVICES FOR CLIENTS";

export const cards = [
  {
    img: faUserShield,
    heading: "Consulting Services",
    desc: " We provide professional guidance in business, management, technology, finance, and strategy to improve performance and achieve long-term goals.",
    delayClass: "delay05s",
  },
  {
    img: faHeadset,
    heading: "Voice Process",
    desc: "We offer voice process services that provide customer support via phone calls as part of our business process outsourcing (BPO) solutions.",
    url: "",
    delayClass: "delay1s",
  },
  {
    img: faPenToSquare,
    heading: "Non-Voice Process",
    desc: "We offer non-voice process services as part of our business process outsourcing (BPO) solutions, providing customer support through email, chat, and other non-voice channels.",
    url: "",
    delayClass: "delay15s",
  },

  {
    img: faLaptopCode,
    heading: "Back office Support",
    desc: " We offer back office support services to help businesses with administrative tasks, data entry, record keeping, and other back-end operation",
    url: "",
    delayClass: "delay05s",
  },
  {
    img: faFileShield,
    heading: "Field Management Support",
    desc: " We offer field management support services to help businesses manage field operations, including scheduling, dispatching, tracking, and reporting of field activities.",
    url: "",
    delayClass: "delay1s",
  },
  {
    img: faClapperboard,
    heading: "Content Production",
    desc: "We offer content production services, including copywriting, editing, and content creation, to help businesses improve their digital presence and engage their audience.",
    url: "",
    delayClass: "delay15s",
  },
];
