import React from "react";
import "./Form.css";
import { validateEmail } from "../../utlts/emailVerification";
import { useDispatch } from "react-redux";
import { closePopup } from "../../redux/actions";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faPaperPlane,
//   faRectangleXmark,
// } from "@fortawesome/free-solid-svg-icons";

export default function Form() {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [warning, setWarning] = React.useState("");

  const dispatch = useDispatch();

  function sendEmail() {
    // e.preventDefault();
    if (!name && !email && !message) {
      setWarning("Name, email and query fields can't be empty.");
    } else if (!email && !message) {
      setWarning("Email and query fields can't be empty.");
    } else if (!name && !message) {
      setWarning("Name and query fields can't be empty.");
    } else if (!name) {
      setWarning("Name field can't be empty.");
    } else if (!email) {
      setWarning("Email field can't be empty.");
    } else if (!validateEmail(email)) {
      setWarning("Invalid email.");
    } else if (!message) {
      setWarning("Query field can't be empty.");
    } else {
      setWarning("");
      // email Send Function
      console.log("email sending...");

      // fetch("/mailer.php", {
      //   method: "POST",
      //   body: { name, email, message },
      //   // body: JSON.stringify(this.state),
      //   headers: {
      //     "Access-Control-Allow-Origin": "*",
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //   },
      // })
      //   .then((response) => response.json())
      //   .then((response) => {
      //     if (response.status === "success") {
      //       alert("Message Sent.");
      //       this.resetForm();
      //     } else if (response.status === "fail") {
      //       alert("Message failed to send.");
      //     }
      //   });

      dispatch(closePopup());
      alert(
        "Thanks for your query. Someone from our team will contact you within next 24 hrs."
      );
    }
  }

  return (
    <div>
      {/* <FontAwesomeIcon
        onClick={() => dispatch(closePopup())}
        className="form-close"
        icon={faRectangleXmark}
      ></FontAwesomeIcon> */}
      {/* <div className="form-heading-div">
        <FontAwesomeIcon
          className="fa-2x"
          icon={faPaperPlane}
        ></FontAwesomeIcon>
        <h1 className="form-main-heading">Get Quote</h1>
      </div> */}

      <p className="form-warning">{warning}</p>

      <p className="form-heading">NAME *</p>
      <input
        type="text"
        id="name"
        className="form-input"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <p className="form-heading">EMAIL *</p>
      <input
        id="email"
        type="email"
        className="form-input"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <p className="form-heading">YOUR QUERY *</p>
      <textarea
        id="message"
        className="form-textArea"
        cols="24"
        rows="10"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      ></textarea>
      <p></p>
      <button className="form-button" onClick={sendEmail}>
        SEND
      </button>
    </div>
  );
}
