/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./NavBar.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Offcanvas } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
//shani have seven now

export default function NavBar() {
  const [show, setShow] = useState(false);

  const scrollTo = (ele) => {
    document.getElementById(ele).scrollIntoView(true);

    console.log(ele);
    setShow(false);
  };

  return (
    <Navbar className="navBar" collapseOnSelect expand="lg">
      <Container className="navContainer">
        <div className="navDiv">
          <Navbar.Brand>
            <img className="LogoImg" src="/qs_logo.png" alt="" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="navbarToggler"
            onClick={() => setShow(true)}
          >
            <FontAwesomeIcon className="navbar-toggler-icon" icon={faBars} />
          </Navbar.Toggle>
        </div>

        <Navbar.Offcanvas
          show={show}
          aria-labelledby={`offcanvasNavbarLabel-expand-md`}
          placement="end"
          className="dark"
          enforceFocus={false}
          restoreFocus={false}
        >
          <Offcanvas.Header>
            <img className="LogoImg" src="/qs_logo.png" alt="logo" />
            <div className="p-2" onClick={() => setShow(false)}>
              <FontAwesomeIcon className="navbar-close-icon" icon={faClose} />
            </div>
          </Offcanvas.Header>

          <Offcanvas.Body>
            {/* <Nav.Item>
              <input type="button" onClick={}>About</input>
            </Nav.Item> */}
            <Nav.Link
              id="navLinks"
              data-link="home"
              eventKey="home"
              onClick={() => scrollTo("home")}
            >
              HOME
            </Nav.Link>
            <Nav.Link id="navLinks" onClick={() => scrollTo("services")}>
              SERVICES
            </Nav.Link>
            <Nav.Link id="navLinks" onClick={() => scrollTo("work")}>
              OUR WORKS
            </Nav.Link>
            <Nav.Link id="navLinks" onClick={() => scrollTo("about")}>
              ABOUT
            </Nav.Link>
            <Nav.Link id="navLinks" onClick={() => scrollTo("blog")}>
              BLOG
            </Nav.Link>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}
