import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import HomePage from "./Pages/HomePage/HomePage";
import { Route, Routes } from "react-router-dom";
import BlogArticle from "./Pages/blogArticle/BlogArticle";
import CareerPage from "./Pages/careerPage/CareerPage";

function App() {
  return (
    // <div className="wrapper">
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/blog/:id" element={<BlogArticle />} />
      <Route path="/career" element={<CareerPage />} />
    </Routes>
    // </div>
  );
}

export default App;
